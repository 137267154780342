<template>
  <b-container
    fluid
    class="grey-100 d-flex text-center align-items-center justify-content-center mb-5"
  >
    <b-overlay :show="busy">
      <b-card class="p-4">
        <b-form @submit.prevent="login">
          <h1>Login</h1>
          <p class="text-muted">Sign In to your account</p>
          <b-alert v-if="error" variant="danger" show>
            {{ error }}
          </b-alert>
          <reg-form-input
            ref="username"
            v-model="username"
            :$v="$v && $v.username"
            field-name="Username"
            placeholder="Username"
            autocomplete="username"
            @change="username = $event"
            @keyup.native.enter="$refs.password.focus()"
          >
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="person" />
              </b-input-group-text>
            </template>
          </reg-form-input>
          <reg-form-input
            ref="password"
            v-model="password"
            :$v="$v && $v.password"
            field-name="Password"
            placeholder="Password"
            type="password"
            autocomplete="curent-password"
            @change="password = $event"
          >
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="lock" />
              </b-input-group-text>
            </template>
          </reg-form-input>
          <b-form-row>
            <b-col cols="12" class="text-right">
              <b-button variant="primary" class="px-4" type="submit">
                Login
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
        <div class="small-ft align-middle mt-3">
          <IrisRecoverPassword :username="username">Forgot your username OR password?</IrisRecoverPassword>
        </div>
      </b-card>
    </b-overlay>
  </b-container>
</template>

<script lang="ts">
import { required } from "vuelidate/lib/validators/index.js";
import jwtDecoder from "jwt-decode";
import { AxiosError } from "axios";

interface JwtErrorModel {
  code: number;
  message: string;
  name: string;
}

/** types of authorities */
export type GrantedAuthorities =
  | "CSSUSER"
  | "ROLE_ADMIN"
  | "ROLE_SCHOOL_ADMIN"
  | "ROLE_TEST_ADMIN"
  | "IRIS_ADMIN"
  | "STUDENT"
  | "NEWPORTALUSER"
  | "PORTALUSER"
  | "FAMILYUSER";

/** the contents of the jwt token from db2 system */
export interface JwtTokenInterface {
  /** userid */
  sub: string;
  /** userid as number */
  userid: number;
  /** login name  */
  username: string;
  lastName: string;
  firstName: string;
  /** users assigned office */
  office: string;
  /** only true if can admin all offices */
  isAdminUser: boolean;
  /** only true if the user is a branch admin - if global admin then this is false */
  isBranchUser: boolean;
  /** only true for consultant */
  isConsultant: boolean;
  /**  */
  grantedAuthorities: GrantedAuthorities[];
  iss: "registration";
  aud: "iris";
  /** token not valid before this time */
  iat: number;
  /** token not valid after this time */
  exp: number;
}

export default defineComponent({
  auth: "guest",
  middleware: ["auth"],
  data: () => {
    return {
      error: null as string | null,
      username: "",
      password: "",
      busy: false,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  head() {
    return {
      title: "Estia Tuition - Login",
    };
  },
  // watch: {
  //   '$auth.loggedIn'(value: boolean) {
  //     if (value) {
  //       // kick user off this page
  //       this.$router.push(this.$auth.options.redirect.home)
  //     }
  //   }
  // },
  mounted() {
    (this.$refs.username as HTMLInputElement).focus();
  },
  methods: {
    forceUpdateLocalValues () {
      const fieldUsernameValue = this.$refs.username.nativeValue()
      if (fieldUsernameValue !== this.username) {
        this.username = fieldUsernameValue
      }
      const fieldPasswordValue = this.$refs.password.nativeValue()
      if (fieldPasswordValue !== this.password) {
        this.password = fieldPasswordValue
      }
    },
    async login() {
      this.forceUpdateLocalValues()
      try {
        this.$v.$touch();
        this.error = null;
        if (this.$v.$invalid) {
          return;
        }
        this.busy = true;
        const resp = await this.$auth.loginWith("refresh", {
          data: {
            username: this.username,
            password: this.password,
            institutes: ["ESTIALABS", "ESTIA"],
          },
        });
        if (resp) {
          // simply decode the jwt and stick under user
          this.$auth.setUser(jwtDecoder<JwtTokenInterface>(resp.data.token));
        }
      } catch (e) {
        this.$v.$reset();
        this.error =
          (e as AxiosError<JwtErrorModel>).response?.data?.message || e.message;
        (this.$refs.username as any).focus();
      } finally {
        this.busy = false;
      }
    },
  },
});
</script>
